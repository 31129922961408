$(document).ready(function() {

    $('[data-toggle="popover"]').popover();

    $('.js-datepicker').datepicker({
        format: 'dd/mm/yyyy',
        language: 'fr',
        calendarWeeks: true,
        todayBtn: true,
        todayHighlight: true,
        autoclose: true
    });

    $('#calendrier-date-saisons .semaine .custom-select').change(function() {
        $.post('/date/modify', {
            date: this.id,
            saison: this.value
        }, function(result) {
            var obj = JSON.parse(result);
            if (obj.status == 'success') {
                var div = $('#' + obj.date).parent().parent();
                div.removeAttr('class');
                div.addClass('semaine saison-' + obj.saison);
            }
        });
    });

    

    // $('basicAutoComplete').autoComplete();
    // $('.basicAutoComplete').autoComplete();

    // console.log($('#basicAutoComplete'));
    // $('#basicAutoComplete').autoComplete({
    //     source: function(term, response){
    //         // console.log($('#basicAutoComplete').attr('data-url'));
    //         $.getJSON($('#basicAutoComplete').attr('data-url'), { q: term }, function(data){ response(data); });
    //     }
    // });
    

});
