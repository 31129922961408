/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

import $ from 'jquery';
// const jQuery = require('jquery');

import '../css/bootstrap.min.css';
import '../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker3.standalone.css';
import '../css/app.css';


import 'bootstrap';
import 'bootstrap-datepicker';
import '../../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min.js';
import './application.js';
